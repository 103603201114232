.main-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mayor-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.avatar {
  height: 150px;
}

.avatar-humdinger {
  content: url("humdinger.bafafa1c.webp");
  border: 5px solid #00f;
}

.avatar-goodway {
  content: url("goodway.27b8ca85.webp");
  border: 5px solid red;
}

.avatar-inactive {
  opacity: .3;
  filter: grayscale();
}

.card-container {
  grid-gap: 10px;
  grid-template-columns: repeat(5, min-content);
  display: grid;
}

.card {
  box-sizing: border-box;
  border: 1px solid gray;
  width: 125px;
  height: 125px;
}

.card-taken {
  opacity: .25;
}

.card-goodway {
  border: 5px solid red !important;
}

.card-humdinger {
  border: 5px solid #00f !important;
}

.card-hidden {
  background-color: #eee;
}

.card-rubble {
  content: url("rubble.244cadc0.webp");
}

.card-marshall {
  content: url("marshall.55ab8619.webp");
}

.card-chase {
  content: url("chase.6520857e.jpg");
}

.card-rocky {
  content: url("rocky.121b805b.webp");
}

.card-zuma {
  content: url("zuma.5045f7a7.webp");
}

.card-skye {
  content: url("skye.156876db.jpg");
}

.card-everest {
  content: url("everest.00765512.png");
}

.card-tracker {
  content: url("tracker.2b158aa3.jpg");
}

.card-rex {
  content: url("rex.3c7a439d.webp");
}

.card-liberty {
  content: url("liberty.9fafa6c7.png");
}

/*# sourceMappingURL=index.e3bc4979.css.map */
