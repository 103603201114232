.main-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mayor-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
}

.avatar {
    height: 150px;
}

.avatar-humdinger {
    border: 5px solid blue;
    content: url('images/humdinger.webp');
}

.avatar-goodway {
    border: 5px solid red;
    content: url('images/goodway.webp');
}

.avatar-inactive {
    opacity: 0.3;
    filter: grayscale(100%);
}

.card-container {
    display: grid;
    grid-template-columns: repeat(5, min-content);
    grid-gap: 10px;
}

.card {
    box-sizing: border-box;
    border: 1px solid grey;
    width: 125px;
    height: 125px;
}

.card-taken {
    opacity: 0.25;
}

.card-goodway {
    border: 5px solid red !important;
}

.card-humdinger {
    border: 5px solid blue !important;
}

.card-hidden {
    background-color: #eeeeee;
}

.card-rubble {
    content: url('images/rubble.webp');
}

.card-marshall {
    content: url('images/marshall.webp');
}

.card-chase {
    content: url('images/chase.jpg');
}

.card-rocky {
    content: url('images/rocky.webp');
}

.card-zuma {
    content: url('images/zuma.webp');
}

.card-skye {
    content: url('images/skye.jpg');
}

.card-everest {
    content: url('images/everest.png');
}

.card-tracker {
    content: url('images/tracker.jpg');
}

.card-rex {
    content: url('images/rex.webp');
}

.card-liberty {
    content: url('images/liberty.png');
}